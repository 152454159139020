<template>

  <div>

    <b-card 
      title="Detalle de estiba">

      <b-row>
        <b-col cols="12">

          <stowage-detail-card :stowage-id="stowageId"/>

        </b-col>
      </b-row>

    </b-card>

    <stowage-boxes :stowage-id="stowageId"/>

  </div>

</template>

<script>
import router from '@/router'
import { BRow, BCol, BTabs, BTab, BCard } from 'bootstrap-vue'
import StowageDetailCard from './StowageDetailCard.vue'
import StowageBoxes from './StowageBoxes.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,

    StowageDetailCard,
    StowageBoxes,
  },
  data() {
    return {
      stowageId: null,
    }
  },
  created() {
    this.stowageId = router.currentRoute.params.stowage_id
    console.log(this.stowageId)
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>