<template>
  <div v-if="stowage">

    <b-card-text>Tipo de producto: {{ stowage.product_type.name }}</b-card-text>
    <b-row>
      <b-col md="4">
        <h4 class="text-primary">Estiba #{{ stowage.stowage_number }}</h4>
        <h4>Total productos en la estiba: {{ totalProducts }}</h4>
        <h5 v-if="!stowage.have_sim" class="text-danger">Estos productos van sin SIM</h5>
      </b-col>

      <b-col md="4">
        <h4>Cajas en la estiba: {{ stowage.available_boxes_count }} / {{ stowage.boxes_by_stowage }}</h4>
      </b-col>

      <b-col 
        md="4"
        class="text-right">
        <b-spinner
          v-show="loading"
          variant="primary"
          label="Loading..."
          class="mb-1 mb-sm-0 mr-0 mt-0"
          />
        <b-button
          v-show="!loading"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mt-0"
          type="submit"
          @click="downloadProducts(stowage.id)"
        >
          Descargar productos
        </b-button>
      </b-col>
      
    </b-row>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BCardText, BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from '@/helpers/constants'
import moment from 'moment';

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    BSpinner,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      serial: '',
      sim: '',
      required,
      stowage: null,
      totalProducts: null,
      loading: false,
    }
  },
  props: {
    stowageId: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    return {
      countryOptions,
    }
  },
  created() {
    this.getStowage()
  },
  methods: {
    
    getStowage() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-stowage/' + this.stowageId, { headers }).then(response => {
        console.log('get-stowage: ', response)

        this.stowage = response.data.data.stowage
        this.totalProducts = response.data.data.total_products
        
      }).catch( err => {
        console.log(err)
      })
    },
    saveProductEarlyEnlistment() {
      event.preventDefault()
      this.$refs.productEnlistmentValidation.validate().then(success => {
        if (success) {

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/save-product-early-enlistment',{
            serial: this.serial,
            sim_serial: this.sim,
            box_id: this.box.id,
            product_type_id: this.stowage.product_type_id,
          }, { headers }).then(response => {
            console.log('save-product-early-enlistment: ', response)

            if(response.data.status) {
              this.serial = ''
              this.sim = ''
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `OK`,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  text: response.data.message,
                },
              })
              this.getStowage()
              /* if(response.data.data.order_complete || response.data.data.finish_order) {
                this.getOrder()
              } */
            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            console.log(err)
          })

        }
      })
    },

    downloadProducts(stowage_id) {

      this.loading = true
      const vm = this

      let xhr = new XMLHttpRequest();
      xhr.open('post', this.host + '/api/front-web/download-products-by-stowage', true);
      xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
      xhr.setRequestHeader("Authorization", 'Bearer ' + this.accessToken);
      xhr.responseType = 'arraybuffer';

      xhr.onload = function () {
        vm.loading = false
        if (this.status === 200) {
          let date = moment().format('DD-MM-YYYY HH:mm:ss');

          let blob = new Blob([this.response], {type: this.getResponseHeader('content-type')});
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = 'estiba - ' + stowage_id + ' - ' + date + '.xlsx';
          link.click();
          
        } else {
          if (callback != null) {
            callback()
          }
        }
      };
      let params = 'stowage_id=' + stowage_id;
      xhr.send(params)
      xhr.th

    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
