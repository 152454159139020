<template>
  <b-card 
    title="Cajas en la estiba"
    sub-title="Cajas">

    <b-row>
      <b-col 
        v-for="(box, index) in boxes"
        :key="index"
        cols="12"
        md="3">

        <b-card
        v-if="box.available == 1"
          no-body
          class="card-apply-job"
          bg-variant="light-secondary"
        >
          <b-card-header class="pb-1">
            <b-media no-body>
              <b-media-body>
                <h5 class="mb-0">
                  Caja #{{ box.box_number }}
                </h5>
                <small class="text-muted">Creada: {{ formatDateVariant(box.created_at) }}</small>
              </b-media-body>
            </b-media>
            <b-badge
              pill
              variant="light-info"
            >
              {{ box.available == 1 ? 'Disponible' : 'No disponible' }}
            </b-badge>
          </b-card-header>
          <b-card-body>

            <!-- payment  -->
            <div class="apply-job-package bg-light-primary rounded">
              <div>
                <h2 class="d-inline mr-25">
                  {{ stowage.enlistment == 1 ? box.products_enlistment_count : box.products_inventory_count  }} / {{ box.products_by_box }}
                </h2>
                <sub class="text-body"><small> seriales</small></sub>
              </div>
            </div>
            <!--/ payment  -->
            <b-button
              block
              variant="primary"
              class="mt-2"
              @click="$router.push({name: 'products-by-box', params: { box_id:box.id } })"
            >
              Ver seriales
            </b-button>

            <!-- <b-button
              v-if="(stowage.enlistment == 1 ? box.products_enlistment_count : box.products_inventory_count) > 0 && box.available == 1"
              block
              variant="danger"
              size="sm"
              class="mt-1"
              @click="openModal(box)"
            >
              Eliminar seriales
            </b-button>
            
            <b-button
              v-else
              block
              variant="info"
              size="sm"
              class="mt-1"
              @click="addSerialsToBox(box)"
            >
              Agregar seriales
            </b-button> -->

          </b-card-body>
        </b-card>

      </b-col>
    </b-row>

    <b-modal
      v-model="deleteProductsModal"
      title="Eliminar seriales de la caja"
      ok-title="Entendido"
      ok-only
      @ok="deleteProductByBox()"
    >
      <b-card-text>
        Al ejecutar esta acción vas a eliminar los seriales de la caja #xx, esta acción no se puede reestablecer, para volver a llenar la caja debes pistolear nuevamente.
      </b-card-text>

      <b-card-text>
        ¿Estás seguro de continuar retirando seriales de la caja?
      </b-card-text>

    </b-modal>

  </b-card>

</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BCardText,
  BBadge,
  BMediaAside,
  BMediaBody,
  BCardBody,
  BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {constants} from "@/helpers/constants"
import {formatDate} from "@/helpers/utils"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardText,
    BBadge,
    BMediaAside,
    BMediaBody,
    BCardBody,
    BModal,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      serial: '',
      sim: '',
      required,
      boxes: null,
      box: null,
      stowage: null,
      totalProducts: null,
      deleteProductsModal: false,
      boxToDelete: null,
    }
  },
  props: {
    stowageId: {
      type: String,
      required: false,
    },
  },
  computed: {
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  created() {
    this.getStowage()
  },
  methods: {
    getStowage() {
      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.get(this.host + '/api/front-web/get-boxes-by-stowage/' + this.stowageId, { headers }).then(response => {
        console.log('get-boxes-by-stowage: ', response)

        this.boxes = response.data.data.boxes
        this.stowage = response.data.data.stowage
        
      }).catch( err => {
        console.log(err)
      })
    },
    openModal(box) {

      this.boxToDelete = box
      this.deleteProductsModal = true

    },
    deleteProductByBox() {

      let headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + this.accessToken
      }
      axios.post(this.host + '/api/front-web/delete-products-by-box',{
        box_id: this.boxToDelete.id,
      }, { headers }).then(response => {
        console.log('delete-products-by-box: ', response)

        if(response.data.status) {
          this.serial = ''
          this.sim = ''
          this.$toast({
            component: ToastificationContentVue,
            position: 'top-right',
            props: {
              title: `OK`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: response.data.message,
            },
          })

          this.getStowage()

        } else {
          this.$toast({
            component: ToastificationContentVue,
            position: 'top-right',
            props: {
              title: `Advertencia`,
              icon: 'XCircleIcon',
              variant: 'danger',
              text: response.data.message,
            },
          })
        }
      }).catch( err => {
        console.log(err)
      })



    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
